import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import styles from "./Timezonebody.module.scss";
import { notification } from "antd";
import TimezoneSelect from "react-timezone-select";
import InfoSvg from "src/assets/Info.svg";
import {
  updateAgentTimezone,
  updateOrganisationTimezone,
  updateOrganisationLanguage,
} from "src/api/OrganizationApi";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { setLanguage } from "src/store/slices/languageSlice";
import BlueInfoIcon from "src/assets/svgs/BlueInfoIcon";
import { getAuthToken, getCountryList, getStateList } from "src/helpers/getLocation";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";
import { FETCH_TERRITORY, UPDATE_TERRITORY } from "src/graphql/territory";
import { useQuery, useMutation } from '@apollo/client';

const Timezonebody = (props) => {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);

  const [selectedOrgTimezone, setSelectedOrgTimezone] = useState({});
  const [selectedAgentTimezone, setSelectedAgentTimezone] = useState({});
  const [countryList, setCountryList] = useState()
  const [selectedCounrty, setSelectedCounrty] = useState()
  const [selectedState, setSelectedState] = useState()
  const [stateList, setStateList] = useState()
  const location_token = useSelector((state) => state?.location?.data?.auth_token)
  const {id:staffId,email} = useSelector((state) => state?.user?.data)
  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');

   // Syncing contry and states
   const { data, loading, error } = useQuery(FETCH_TERRITORY, {
    variables: { staffId: staffId },
  });
  
  
  const [updateTerritory] = useMutation(UPDATE_TERRITORY) 

  const dispatch = useDispatch();
  // const lang = useSelector((store)=> store?.language?.language)
  const [selectedLanguage, setSelectedLanguage] = useState("");
  React.useEffect(() => {
    if (
      props?.selectedOrgTimezone !== null &&
      props?.orgTimezone !== undefined &&
      Object.keys(props?.orgTimezone).length !== 0
    ) {
      setSelectedOrgTimezone(props.orgTimezone);
    }
    if (
      props?.agentTimezone !== null &&
      props?.agentTimezone !== undefined &&
      Object.keys(props?.agentTimezone).length !== 0
    ) {
      setSelectedAgentTimezone(props.agentTimezone);
    }
    if (props?.orgLanguage !== "") {
      setSelectedLanguage(props.orgLanguage);
    }
  }, [
    props?.orgTimezone,
    props?.agentTimezone,
    props?.orgLanguage,
    props?.selectedOrgTimezone,
  ]);

useEffect(() => {
    if (!location_token) {
      getAuthToken(dispatch)
    }
    if(!loading && data && data.queryHC_Staff[0]?.territory){
      setSelectedCounrty(data?.queryHC_Staff[0]?.territory?.contries)
      setSelectedState(data?.queryHC_Staff[0]?.territory?.states)
    }
    if (error) {
      console.error('Error fetching territory:', error);
    }
  }, [data])

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        if (location_token) {
          const countries = await getCountryList();
          setCountryList(countries);
        }
      } catch (error) {
        console.error("error fetching counrty", error)
      }
    };

    fetchCountryList();
  }, [])

  const handleOrgTimezone = async (value) => {
    try {
      let timezoneVal = `${value.abbrev}>${value.altName}>${value.label}>${value.offset}>${value.value}`;
      let payload = {
        timezone: timezoneVal,
        organizationId: organizationId,
      };
      const res = await updateOrganisationTimezone(payload);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });

        setSelectedOrgTimezone(value);
      } else {
        if (res.data.error) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.error,
          });
        } else if (res.data.success === false) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.message,
          });
        }
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: "We're encountering some problem processing your request",
      });
    }
  };

  const handleAgentTimezone = async (value) => {
    try {
      let timezoneVal = `${value.abbrev}>${value.altName}>${value.label}>${value.offset}>${value.value}`;
      let payload = {
        timezone: timezoneVal,
        email,
      };
      const res = await updateAgentTimezone(payload);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });
        setSelectedAgentTimezone(value);
      } else {
        if (res.data.error) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.error,
          });
        } else if (res.data.success === false) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.message,
          });
        }
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: "We're encountering some problem processing your request",
      });
    }
  };

  const language = {
    ar_EG: "Arabic",
    en_US: "English",
    hi_IN: "Hindi",
    it_IT: "Italian",
    fr_FR: "French",
  };
  const handleLanguage = async (lang) => {
    try {
      let currentLanguage = language[lang];
      let payload = {
        language: currentLanguage,
        organizationId: organizationId,
      };
      const res = await updateOrganisationLanguage(payload);
      if (res.data.success) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });
        setSelectedLanguage(currentLanguage);
        dispatch(setLanguage(lang));
      } else {
        notification.error({
          message: <b>Notification</b>,
          description: res.data.message,
        });
      }
    } catch (error) {
      notification.error({
        message: <b>Notification</b>,
        description: "Language could not be set due to internal problem.",
      });
    }
  };

  const handleStateSelect = async (state) => {
    setSelectedState(state)
    try{
      await updateTerritory({
        variables: { staffId, contries: selectedCounrty, states: state },
      });
    }catch (error) {
      console.error("error updating territory", error)
    }
  }

  const handleCountrySelect = async (country) => {
    setSelectedCounrty(country)
    try {
      await updateTerritory({
        variables: { staffId, contries: country, states: selectedState },
      });
      if (location_token) {
        const states = await getStateList(country[country.length - 1]);
        setStateList(states);
      }
    } catch (error) {
      console.error("error updating state", error)
    }
  }

  return (
    <div className={styles.Timezonebody}>
      <div>
        <div className={styles.Timezonebody__first_container}>
          {checkFeatureAvailability("locationFeature",orgUrl) && <div className={styles.Timezonebody__first_container__DisabledOverlay} />}
          <h4>{t("Timezone")}</h4>
          <div className={styles.Timezonebody__first_container__inner_div}>
            <div
              className={styles.Timezonebody__first_container__inner_div__info}
            >
              <p>{t("Set your timezone")}</p>
              <img src={InfoSvg} alt="info" />
            </div>
            <TimezoneSelect
              placeholder={t("Select timezone")}
              value={selectedAgentTimezone}
              onChange={(e) => handleAgentTimezone(e)}
            />
          </div>
          {props.activeOrg !== null && props.activeOrg?.roleName === "admin" ? (
            <>
              <div className={styles.Timezonebody__first_container__inner_div}>
                <div
                  className={
                    styles.Timezonebody__first_container__inner_div__info
                  }
                >
                  <p>{t("Set organisation timezone")}</p>
                  <img src={InfoSvg} alt="info" />
                </div>
                <TimezoneSelect
                  placeholder={t("Select timezone")}
                  value={selectedOrgTimezone}
                  onChange={(e) => handleOrgTimezone(e)}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={styles.Timezonebody__first_container__disabled_div}
              >
                <div
                  className={styles.Timezonebody__first_container__inner_div}
                >
                  <div
                    className={
                      styles.Timezonebody__first_container__inner_div__info
                    }
                  >
                    <p className={styles.disabled}>
                      {t("Set organisation timezone")}
                    </p>
                    <img src={InfoSvg} alt="info" />
                  </div>

                  <TimezoneSelect
                    placeholder={t("Select timezone")}
                    value={selectedOrgTimezone}
                    onChange={(e) => handleOrgTimezone(e)}
                    isDisabled={"true"}
                  />
                </div>
                <p
                  className={
                    styles.Timezonebody__first_container__disabled_div__msg
                  }
                >
                  <span style={{ display: 'flex', justifyContent: 'center', marginTop: "0.3em" }}><BlueInfoIcon /> &nbsp;</span>
                  The organization time zone is managed by the admin
                </p>
              </div>
            </>
          )}
        </div>
        <Divider />
        <div className={styles.language}>
          {checkFeatureAvailability("locationFeature",orgUrl) && <div className={styles.language__DisabledOverlay} />}
          <h4>{t("Language")}</h4>
          <div className={styles.language__container}>
            <div className={styles.language__container__label}>
              <p >{t("Set app interface language")}</p>
              <img src={InfoSvg} alt="info" />
            </div>
            <Select
              value={selectedLanguage}
              onChange={handleLanguage}
              style={{ width: "50%" }}
              options={[
                { value: "en_US", label: "English" },
                { value: "it_IT", label: "Italian" },
              ]}
            />
          </div>
        </div>
        {checkFeatureAvailability("locationFeature", orgUrl) &&
          <Divider />}
        {checkFeatureAvailability("locationFeature", orgUrl) &&
          <div className={styles.language}>
            <h4>{t("Territory")}</h4>
            <br />
            <div className={styles.language__container}>
              <div className={styles.language__container__label}>
                <p>{t("Assign Country")}</p>
              </div>
              <Select
                value={selectedCounrty}
                onChange={handleCountrySelect}
                style={{ width: "50%" }}
                options={countryList}
                mode="multiple"
              />
            </div>
            <br />
            <div className={styles.language__container}>
              <div className={styles.language__container__label}>
                <p>{t("Assign State")}</p>
              </div>
              <Select
                value={selectedState}
                onChange={handleStateSelect}
                style={{ width: "50%" }}
                options={stateList}
                mode="multiple"
              />
            </div>
          </div>}
      </div>
    </div>
  );
};

Timezonebody.propTypes = {};

Timezonebody.defaultProps = {};

export default Timezonebody;
