import React, { useState } from 'react';
import style from './QueriesList.module.scss';
import { Table, Tag, Tooltip, Button } from 'antd';
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv

const renderTextWithLinks = (text) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text.split('\n').map((line, lineIndex) => (
    <div key={lineIndex}>
      {line.split(urlPattern).map((part, partIndex) => {
        if (urlPattern.test(part)) {
          return (
            <a
              key={partIndex}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1890ff', textDecoration: 'underline' }}
            >
              {part}
            </a>
          );
        }
        return part;
      })}
    </div>
  ));
};

// Function to check if a query contains "Contact us"
const isContactUsQuery = (text) => {
  return text.includes("Contact us");
};

const columns = (onQueryClick) => [
  {
    title: 'Inquiries',
    dataIndex: 'type',
    key: 'inquiry',
    responsive: ['xs', 'sm', 'md', 'lg'],
    ellipsis: true,
    render: (text, record) => {
      const color = record.type === 'product-related' ? '#E6ECEE' : '#EDEEE6';

      return (
        !isContactUsQuery(record.query) && (
          <Tooltip color={color} title={`Query: ${record.query}`} overlayInnerStyle={{ color: 'black' }}>
            <span
              style={{ color: 'black', cursor: 'pointer' }}
              onClick={() => onQueryClick(record)}
            >
              Query: {renderTextWithLinks(record.query)}
              <br />
              <Tag style={{ color: 'black' }} color={color}>
                {record.type}
              </Tag>
            </span>
          </Tooltip>
        )
      );
    }
  },
  {
    title: 'No. of times triggered',
    dataIndex: 'count',
    key: 'count',
    responsive: ['xs', 'sm', 'md', 'lg'],
  },
];

const TopQueries = ({ data }) => {
  const [selectedQuery, setSelectedQuery] = useState(null);

  const handleQueryClick = (query) => {
    setSelectedQuery(query);
  };

  const handleBackToList = () => {
    setSelectedQuery(null);
  };

  // Prepare CSV data for selected query with each response in a separate column
  const singleQueryCSVData = selectedQuery && !isContactUsQuery(selectedQuery.query) ? [
    ['Query', ...selectedQuery.responses.map((_, index) => `Response ${index + 1}`)],
    [
      selectedQuery.query,
      ...selectedQuery.responses
    ]
  ] : [];

  // Prepare CSV data for all queries with each response in a separate column, excluding "Contact us" queries
  const maxResponses = Math.max(...data.filter(record => !isContactUsQuery(record.query))
                                     .map(record => record.responses.length), 1);
  const allQueriesCSVData = data.filter(record => !isContactUsQuery(record.query)).map(record => [
    record.query,
    ...Array(maxResponses).fill('').map((_, index) => record.responses[index] || '') // Fill empty responses if fewer responses are present
  ]);
  allQueriesCSVData.unshift(['Query', ...Array(maxResponses).fill('').map((_, index) => `Response ${index + 1}`)]); // Add header row

  return (
    <div className={style.queryList}>
      {selectedQuery ? (
        <div style={{ maxHeight: '450px', overflowY: 'scroll', minHeight: '450px' }}>
          <div style={{display: 'flex', justifyContent: 'space-between' }}>
          <h2 className={style.chartHeader}>
            <LeftOutlined
              style={{ cursor: 'pointer', marginRight: '5px' }}
              onClick={handleBackToList}
            />
            Queries and Responses
          </h2>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{ marginBottom: '10px', backgroundColor: '#055b7f',marginRight: '10px' }}
          >
            <CSVLink
              data={singleQueryCSVData}
              filename="queries_and_responses.csv"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Export
            </CSVLink>
          </Button>

          </div>
          <h3>Query: {selectedQuery.query}</h3>
          <p style={{ marginTop: 10 }}><strong>Responses:</strong></p>
          <ul>
            {selectedQuery.responses.length > 0 ? (
              selectedQuery.responses.map((response, index) => (
                <li style={{ marginTop: 10 }} key={index}>{renderTextWithLinks(response)}</li>
              ))
            ) : (
              <li>No responses available.</li>
            )}
          </ul>
        </div>
      ) : (
        <div style={{ maxHeight: '450px', overflowY: 'scroll' }}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>

          <h2 className={style.chartHeader}>Top Queries Raised with Chatbot</h2>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{ marginRight: '10px', backgroundColor: '#055b7f' }}
          >
            <CSVLink
              data={allQueriesCSVData}
              filename="all_queries_and_responses.csv"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Export
            </CSVLink>
          </Button>
          </div>
          <Table
            columns={columns(handleQueryClick)}
            dataSource={data.filter(record => !isContactUsQuery(record.query))}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default TopQueries;
